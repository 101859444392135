import { memo } from "react";
import Box from "@mui/material/Box";
import { DiscountNotificationTrigger } from "@/components/DiscountBanner";

const DiscountCodeBannerTrigger = () => (
    <DiscountNotificationTrigger
        sx={{
            '&.MuiButtonBase-root': {
                minWidth: 150,
                position: 'relative',
                textTransform: 'none',
                color: 'var(--color-vibrant-pink)',
                borderStyle: 'dashed',
                borderWidth: 2,
                borderRadius: '21px',
                fontFamily: 'inherit',
                fontSize: '1rem',
                borderColor: 'var(--color-vibrant-pink)',
                bgcolor: 'var(--color-white)',
                p: 0,
                pl: 2,

                '& .MuiButton-endIcon': {
                    border: 'inherit',
                    borderStyle: 'solid',
                    color: 'var(--color-white)',
                    bgcolor: 'var(--color-vibrant-pink)',
                    width: 45,
                    minWidth: 45,
                    height: 45,
                    minHeight: 45,
                    borderRadius: '50%',
                    display: 'grid',
                    placeItems: 'center'
                },

                '@media (hover: hover)': {
                    ':hover': {
                        bgcolor: 'var(--color-vibrant-pink)',
                        color: 'var(--color-white)',

                        '& .MuiButton-endIcon': {
                            bgcolor: 'var(--color-white)',
                            color: 'var(--color-vibrant-pink)'
                        }
                    }
                }
            }
        }}
    >
        <Box
            sx={{
                display: 'grid',
                placeContent: 'center'
            }}
        >
            <Box
                sx={{
                    display: 'none',
                    whiteSpace: 'nowrap',

                    '@media (min-width: 980px)': {
                        display: 'flex'
                    }
                }}
            >
                Global<strong>sale</strong>
            </Box>
            <Box
                sx={{
                    display: 'inherit',
                    placeContent: 'inherit',
                    '@media (min-width: 980px)': {
                        display: 'none'
                    }
                }}
            >
               <svg width="101" height="16" viewBox="0 0 101 16" fill="none">
                    <path 
                        d="M101 11.416V11.765H99.1468V13.3354H100.711V13.6844H99.1468V15.6218H98.7256V11.416H101Z" fill="black"/>
                    <path 
                        d="M98.0312 11.416V11.765H96.178V13.3354H97.7424V13.6844H96.178V15.6218H95.7568V11.416H98.0312Z" fill="black"/>
                    <path 
                        d="M92.9041 15.6613C92.515 15.6613 92.162 15.571 91.8451 15.3905C91.5282 15.206 91.2775 14.9513 91.093 14.6264C90.9125 14.2975 90.8223 13.9264 90.8223 13.5133C90.8223 13.1001 90.9125 12.7311 91.093 12.4062C91.2775 12.0772 91.5282 11.8225 91.8451 11.642C92.162 11.4575 92.515 11.3652 92.9041 11.3652C93.2972 11.3652 93.6522 11.4575 93.9691 11.642C94.286 11.8225 94.5347 12.0772 94.7152 12.4062C94.8957 12.7311 94.9859 13.1001 94.9859 13.5133C94.9859 13.9264 94.8957 14.2975 94.7152 14.6264C94.5347 14.9513 94.286 15.206 93.9691 15.3905C93.6522 15.571 93.2972 15.6613 92.9041 15.6613ZM92.9041 15.2942C93.217 15.2942 93.4978 15.222 93.7465 15.0776C93.9952 14.9332 94.1917 14.7267 94.3361 14.4579C94.4805 14.1851 94.5527 13.8703 94.5527 13.5133C94.5527 13.1563 94.4805 12.8434 94.3361 12.5746C94.1917 12.3059 93.9952 12.0993 93.7465 11.9549C93.4978 11.8105 93.217 11.7383 92.9041 11.7383C92.5912 11.7383 92.3104 11.8105 92.0617 11.9549C91.813 12.0993 91.6165 12.3059 91.4721 12.5746C91.3277 12.8434 91.2555 13.1563 91.2555 13.5133C91.2555 13.8703 91.3277 14.1851 91.4721 14.4579C91.6165 14.7267 91.813 14.9332 92.0617 15.0776C92.3104 15.222 92.5912 15.2942 92.9041 15.2942Z" fill="black"/>
                    <path 
                        d="M92.0508 1.936C92.0508 1.35836 92.2284 0.893539 92.5836 0.541539C92.948 0.180513 93.408 0 93.9636 0C94.5284 0 94.9884 0.180513 95.3436 0.541539C95.708 0.893539 95.8901 1.35836 95.8901 1.936C95.8901 2.52267 95.708 2.99652 95.3436 3.35754C94.9884 3.70954 94.5284 3.88554 93.9636 3.88554C93.408 3.88554 92.948 3.70954 92.5836 3.35754C92.2284 2.99652 92.0508 2.52267 92.0508 1.936ZM99.7022 0.135385L94.1003 9.61232H93.1302L98.7457 0.135385H99.7022ZM93.9636 0.609232C93.6175 0.609232 93.3397 0.726565 93.1302 0.961231C92.9298 1.18687 92.8296 1.5118 92.8296 1.936C92.8296 2.36021 92.9298 2.68964 93.1302 2.92431C93.3397 3.15898 93.6175 3.27631 93.9636 3.27631C94.3098 3.27631 94.5876 3.15898 94.7971 2.92431C95.0066 2.68964 95.1113 2.36021 95.1113 1.936C95.1113 1.52082 95.0066 1.1959 94.7971 0.961231C94.5876 0.726565 94.3098 0.609232 93.9636 0.609232ZM96.9695 7.8117C96.9695 7.22503 97.1472 6.7557 97.5024 6.4037C97.8667 6.04267 98.3267 5.86216 98.8824 5.86216C99.438 5.86216 99.8935 6.04267 100.249 6.4037C100.613 6.7557 100.795 7.22503 100.795 7.8117C100.795 8.39837 100.613 8.87221 100.249 9.23324C99.8935 9.58524 99.438 9.76124 98.8824 9.76124C98.3267 9.76124 97.8667 9.58524 97.5024 9.23324C97.1472 8.87221 96.9695 8.39837 96.9695 7.8117ZM98.8824 6.48493C98.5362 6.48493 98.2584 6.60226 98.0489 6.83693C97.8394 7.0716 97.7347 7.39652 97.7347 7.8117C97.7347 8.22688 97.8394 8.5518 98.0489 8.78647C98.2584 9.02114 98.5362 9.13847 98.8824 9.13847C99.2285 9.13847 99.5018 9.02565 99.7022 8.80001C99.9117 8.56534 100.016 8.23591 100.016 7.8117C100.016 7.38749 99.9117 7.06257 99.7022 6.83693C99.5018 6.60226 99.2285 6.48493 98.8824 6.48493Z" fill="black"/>
                    <path 
                        d="M88.193 2.63477H81.2303V6.29774C81.5311 5.92644 81.9609 5.62655 82.5197 5.39806C83.0784 5.15529 83.673 5.0339 84.3034 5.0339C85.4495 5.0339 86.3879 5.28381 87.1185 5.78363C87.8492 6.28345 88.3793 6.92608 88.7088 7.71151C89.0383 8.48266 89.2031 9.31094 89.2031 10.1963C89.2031 11.8386 88.7303 13.1596 87.7847 14.1592C86.8535 15.1588 85.5211 15.6587 83.7876 15.6587C82.1544 15.6587 80.8506 15.2517 79.8764 14.4377C78.9022 13.6237 78.3506 12.5598 78.2217 11.246H81.1443C81.2733 11.8172 81.5598 12.2742 82.0039 12.6169C82.4624 12.9596 83.0426 13.131 83.7446 13.131C84.5899 13.131 85.2274 12.8668 85.6572 12.3384C86.087 11.81 86.3019 11.1103 86.3019 10.2392C86.3019 9.35378 86.0799 8.68259 85.6357 8.22561C85.2059 7.75435 84.5684 7.51872 83.7231 7.51872C83.1214 7.51872 82.62 7.66867 82.2188 7.96856C81.8177 8.26845 81.5311 8.66831 81.3592 9.16813H78.4796V0H88.193V2.63477Z" fill="black"/>
                    <path 
                        d="M69.4785 2.93801V0.203125H74.7659V15.6584H71.6066V2.93801H69.4785Z" fill="black"/>
                    <path 
                        d="M17.7178 15.7536V8.94336H22.1152V9.94613H18.9632V11.8527H21.5127V12.8345H18.9632V15.7536H17.7178Z" fill="black"/>
                    <path 
                        d="M22.709 15.7536V8.94336H23.9544V15.7536H22.709Z" fill="black"/>
                    <path 
                        d="M24.9004 15.7536V8.94336H27.401C27.9457 8.94336 28.3948 9.03611 28.7483 9.2209C29.1015 9.40604 29.3655 9.65604 29.5405 9.97124C29.7162 10.2857 29.8041 10.6337 29.8041 11.0159C29.8041 11.4315 29.6953 11.8115 29.4777 12.1553C29.2602 12.4984 28.9212 12.7477 28.461 12.9029L29.8724 15.7536H28.4415L27.1765 13.0688H26.1458V15.7536H24.9004ZM26.1458 12.1539H27.3229C27.7375 12.1539 28.0419 12.0535 28.2365 11.8527C28.4317 11.6518 28.5293 11.3861 28.5293 11.0549C28.5293 10.7306 28.4335 10.4712 28.242 10.2767C28.0503 10.0825 27.7407 9.98518 27.3132 9.98518H26.1458V12.1539Z" fill="black"/>
                    <path 
                        d="M32.9182 15.8752C32.4189 15.8752 31.9775 15.789 31.5946 15.6171C31.2125 15.4452 30.9112 15.1987 30.6909 14.878C30.4705 14.5565 30.3569 14.1653 30.3506 13.705H31.6644C31.6773 14.023 31.792 14.2908 32.0089 14.5084C32.2264 14.7252 32.5263 14.8333 32.9084 14.8333C33.2393 14.8333 33.5019 14.7545 33.6964 14.5962C33.8917 14.4372 33.9893 14.2249 33.9893 13.9589C33.9893 13.6799 33.9032 13.4631 33.7313 13.3076C33.559 13.1514 33.3303 13.0251 33.0451 12.9282C32.7595 12.8306 32.4545 12.7267 32.1302 12.6158C31.6048 12.4345 31.2045 12.2012 30.9294 11.9157C30.6539 11.6305 30.5166 11.2511 30.5166 10.7776C30.5099 10.3752 30.6051 10.0297 30.8025 9.74136C31.0005 9.45336 31.2711 9.22951 31.6142 9.07052C31.958 8.91153 32.354 8.83203 32.8024 8.83203C33.256 8.83203 33.6563 8.91362 34.0032 9.0761C34.3498 9.23788 34.6225 9.46487 34.8205 9.7567C35.0186 10.0478 35.1239 10.3948 35.1371 10.7971H33.8038C33.7972 10.5572 33.7048 10.3449 33.5263 10.1598C33.3477 9.97497 33.0998 9.88222 32.7829 9.88222C32.5102 9.87595 32.2815 9.9422 32.0967 10.0817C31.9116 10.2211 31.8192 10.4241 31.8192 10.6897C31.8192 10.9167 31.8903 11.0966 32.0326 11.2295C32.1755 11.3627 32.3704 11.4746 32.6169 11.5656C32.8631 11.6559 33.1455 11.7532 33.4635 11.8571C33.801 11.9742 34.1085 12.1106 34.3868 12.2657C34.6657 12.4212 34.8892 12.627 35.0576 12.8836C35.2267 13.1395 35.3115 13.4686 35.3115 13.871C35.3115 14.2273 35.2208 14.5579 35.0395 14.8626C34.8582 15.1677 34.589 15.4128 34.232 15.5976C33.8749 15.7824 33.437 15.8752 32.9182 15.8752Z" fill="black"/>
                    <path 
                        d="M37.6174 15.7536V9.94613H35.6328V8.94336H40.8573V9.94613H38.8629V15.7536H37.6174Z" fill="black"/>
                    <path 
                        d="M45.6789 15.8752C45.1796 15.8752 44.7382 15.789 44.3554 15.6171C43.9732 15.4452 43.672 15.1987 43.4516 14.878C43.2313 14.5565 43.1176 14.1653 43.1113 13.705H44.4251C44.438 14.023 44.5527 14.2908 44.7696 14.5084C44.9872 14.7252 45.287 14.8333 45.6692 14.8333C46.0001 14.8333 46.2626 14.7545 46.4572 14.5962C46.6524 14.4372 46.75 14.2249 46.75 13.9589C46.75 13.6799 46.6639 13.4631 46.492 13.3076C46.3198 13.1514 46.0911 13.0251 45.8058 12.9282C45.5203 12.8306 45.2152 12.7267 44.8909 12.6158C44.3655 12.4345 43.9652 12.2012 43.6901 11.9157C43.4147 11.6305 43.2773 11.2511 43.2773 10.7776C43.2707 10.3752 43.3659 10.0297 43.5632 9.74136C43.7612 9.45336 44.0318 9.22951 44.3749 9.07052C44.7187 8.91153 45.1148 8.83203 45.5632 8.83203C46.0168 8.83203 46.4171 8.91362 46.764 9.0761C47.1106 9.23788 47.3832 9.46487 47.5813 9.7567C47.7793 10.0478 47.8846 10.3948 47.8979 10.7971H46.5646C46.5579 10.5572 46.4655 10.3449 46.287 10.1598C46.1085 9.97497 45.8606 9.88222 45.5436 9.88222C45.271 9.87595 45.0423 9.9422 44.8575 10.0817C44.6723 10.2211 44.5799 10.4241 44.5799 10.6897C44.5799 10.9167 44.6511 11.0966 44.7933 11.2295C44.9363 11.3627 45.1312 11.4746 45.3777 11.5656C45.6238 11.6559 45.9063 11.7532 46.2242 11.8571C46.5618 11.9742 46.8693 12.1106 47.1475 12.2657C47.4265 12.4212 47.65 12.627 47.8184 12.8836C47.9875 13.1395 48.0722 13.4686 48.0722 13.871C48.0722 14.2273 47.9815 14.5579 47.8002 14.8626C47.6189 15.1677 47.3498 15.4128 46.9927 15.5976C46.6357 15.7824 46.1978 15.8752 45.6789 15.8752Z" fill="black"/>
                    <path 
                        d="M50.3811 15.7536V9.94613H48.3965V8.94336H53.6209V9.94613H51.6266V15.7536H50.3811Z" fill="black"/>
                    <path 
                        d="M53.7363 15.7536L56.2272 8.94336H57.6275L60.1184 15.7536H58.7948L58.2509 14.1776H55.5954L55.0404 15.7536H53.7363ZM55.9357 13.2055H57.9106L56.9176 10.3743L55.9357 13.2055Z" fill="black"/>
                    <path 
                        d="M62.3835 15.7536V13.331L60.0977 8.94336H61.5077L63.0153 12.0856L64.5132 8.94336H65.9051L63.629 13.331V15.7536H62.3835Z" fill="black"/>
                    <path 
                        d="M61.2021 6.74V0.15625H63.4267C63.9411 0.15625 64.3633 0.24412 64.6933 0.419858C65.0234 0.593453 65.2677 0.832414 65.4263 1.13674C65.5849 1.44107 65.6642 1.78719 65.6642 2.1751C65.6642 2.56301 65.5849 2.90698 65.4263 3.20702C65.2677 3.50706 65.0245 3.74281 64.6966 3.91426C64.3687 4.08357 63.9497 4.16822 63.4396 4.16822H61.6393V3.44813H63.4139C63.7654 3.44813 64.0482 3.39669 64.2626 3.29382C64.479 3.19095 64.6355 3.04521 64.7319 2.85662C64.8305 2.66588 64.8798 2.4387 64.8798 2.1751C64.8798 1.91149 64.8305 1.6811 64.7319 1.48393C64.6333 1.28676 64.4758 1.1346 64.2593 1.02744C64.0429 0.918139 63.7568 0.863489 63.401 0.863489H61.9994V6.74H61.2021ZM64.3011 3.78246L65.9214 6.74H64.9955L63.401 3.78246H64.3011Z" fill="black"/>
                    <path 
                        d="M58.8138 0.15625H59.611V4.51541C59.611 4.96547 59.5049 5.36731 59.2927 5.72093C59.0827 6.07241 58.7859 6.34995 58.4023 6.55355C58.0186 6.755 57.5686 6.85573 57.0521 6.85573C56.5356 6.85573 56.0855 6.755 55.7019 6.55355C55.3183 6.34995 55.0204 6.07241 54.8082 5.72093C54.5982 5.36731 54.4932 4.96547 54.4932 4.51541V0.15625H55.2904V4.45112C55.2904 4.77259 55.3611 5.0587 55.5026 5.30945C55.644 5.55805 55.8455 5.75415 56.107 5.89774C56.3706 6.03919 56.6856 6.10991 57.0521 6.10991C57.4186 6.10991 57.7336 6.03919 57.9972 5.89774C58.2608 5.75415 58.4623 5.55805 58.6016 5.30945C58.743 5.0587 58.8138 4.77259 58.8138 4.45112V0.15625Z" fill="black"/>
                    <path 
                        d="M53.153 3.44829C53.153 4.14267 53.0277 4.74275 52.7769 5.24854C52.5262 5.75432 52.1822 6.14437 51.745 6.4187C51.3078 6.69302 50.8084 6.83018 50.2469 6.83018C49.6854 6.83018 49.1861 6.69302 48.7489 6.4187C48.3117 6.14437 47.9677 5.75432 47.7169 5.24854C47.4662 4.74275 47.3408 4.14267 47.3408 3.44829C47.3408 2.75391 47.4662 2.15383 47.7169 1.64805C47.9677 1.14227 48.3117 0.752214 48.7489 0.477891C49.1861 0.203568 49.6854 0.0664062 50.2469 0.0664062C50.8084 0.0664062 51.3078 0.203568 51.745 0.477891C52.1822 0.752214 52.5262 1.14227 52.7769 1.64805C53.0277 2.15383 53.153 2.75391 53.153 3.44829ZM52.3815 3.44829C52.3815 2.87822 52.2861 2.39708 52.0954 2.00488C51.9068 1.61269 51.6507 1.31586 51.3271 1.11441C51.0056 0.91295 50.6456 0.812221 50.2469 0.812221C49.8483 0.812221 49.4872 0.91295 49.1636 1.11441C48.8421 1.31586 48.586 1.61269 48.3953 2.00488C48.2067 2.39708 48.1124 2.87822 48.1124 3.44829C48.1124 4.01837 48.2067 4.49951 48.3953 4.8917C48.586 5.2839 48.8421 5.58072 49.1636 5.78218C49.4872 5.98364 49.8483 6.08436 50.2469 6.08436C50.6456 6.08436 51.0056 5.98364 51.3271 5.78218C51.6507 5.58072 51.9068 5.2839 52.0954 4.8917C52.2861 4.49951 52.3815 4.01837 52.3815 3.44829Z" fill="black"/>
                    <path 
                        d="M41.3193 0.15625H42.2323L44.0583 3.22952H44.1354L45.9614 0.15625H46.8744L44.4955 4.02678V6.74H43.6982V4.02678L41.3193 0.15625Z" fill="black"/>
                    <path 
                        d="M37.7451 0.15625V6.74H36.9736L33.386 1.57073H33.3217V6.74H32.5244V0.15625H33.2959L36.8964 5.33838H36.9607V0.15625H37.7451Z" fill="black"/>
                    <path 
                        d="M31.1843 3.44829C31.1843 4.14267 31.0589 4.74275 30.8082 5.24854C30.5574 5.75432 30.2134 6.14437 29.7762 6.4187C29.339 6.69302 28.8397 6.83018 28.2782 6.83018C27.7167 6.83018 27.2173 6.69302 26.7801 6.4187C26.3429 6.14437 25.9989 5.75432 25.7482 5.24854C25.4974 4.74275 25.3721 4.14267 25.3721 3.44829C25.3721 2.75391 25.4974 2.15383 25.7482 1.64805C25.9989 1.14227 26.3429 0.752214 26.7801 0.477891C27.2173 0.203568 27.7167 0.0664062 28.2782 0.0664062C28.8397 0.0664062 29.339 0.203568 29.7762 0.477891C30.2134 0.752214 30.5574 1.14227 30.8082 1.64805C31.0589 2.15383 31.1843 2.75391 31.1843 3.44829ZM30.4128 3.44829C30.4128 2.87822 30.3174 2.39708 30.1266 2.00488C29.938 1.61269 29.6819 1.31586 29.3583 1.11441C29.0369 0.91295 28.6768 0.812221 28.2782 0.812221C27.8796 0.812221 27.5184 0.91295 27.1948 1.11441C26.8733 1.31586 26.6172 1.61269 26.4265 2.00488C26.2379 2.39708 26.1436 2.87822 26.1436 3.44829C26.1436 4.01837 26.2379 4.49951 26.4265 4.8917C26.6172 5.2839 26.8733 5.58072 27.1948 5.78218C27.5184 5.98364 27.8796 6.08436 28.2782 6.08436C28.6768 6.08436 29.0369 5.98364 29.3583 5.78218C29.6819 5.58072 29.938 5.2839 30.1266 4.8917C30.3174 4.49951 30.4128 4.01837 30.4128 3.44829Z" fill="black"/>
                    <path 
                        d="M17.6719 6.74V0.15625H21.6453V0.863489H18.4691V3.08808H21.4395V3.79532H18.4691V6.03276H21.6967V6.74H17.6719Z" fill="black"/>
                    <path 
                        d="M11.8241 0.15625L13.7787 5.69843H13.8558L15.8104 0.15625H16.6462L14.2287 6.74H13.4058L10.9883 0.15625H11.8241Z" fill="black"/>
                    <path 
                        d="M6.32606 6.74H5.49023L7.90771 0.15625H8.73067L11.1481 6.74H10.3123L8.34491 1.19782H8.29347L6.32606 6.74ZM6.63468 4.16822H10.0037V4.87546H6.63468V4.16822Z" fill="black"/>
                    <path 
                        d="M3.88338 1.80236C3.84481 1.4766 3.68836 1.22371 3.41403 1.04368C3.13971 0.863657 2.80324 0.773645 2.40461 0.773645C2.11314 0.773645 1.85811 0.820794 1.63951 0.915093C1.42305 1.00939 1.25374 1.13905 1.13158 1.30407C1.01157 1.4691 0.951558 1.65662 0.951558 1.86665C0.951558 2.04239 0.993349 2.19348 1.07693 2.31993C1.16266 2.44423 1.27196 2.54817 1.40483 2.63175C1.53771 2.71319 1.67701 2.7807 1.82275 2.83428C1.96848 2.88572 2.10243 2.92751 2.22459 2.95965L2.89325 3.13968C3.0647 3.18468 3.25544 3.24684 3.46547 3.32613C3.67764 3.40543 3.88017 3.51366 4.07305 3.65082C4.26808 3.78584 4.42881 3.95943 4.55526 4.17161C4.68171 4.38378 4.74493 4.64417 4.74493 4.95278C4.74493 5.30854 4.6517 5.63002 4.46525 5.9172C4.28094 6.20438 4.0109 6.43263 3.65514 6.60193C3.30152 6.77124 2.87182 6.8559 2.36603 6.8559C1.89454 6.8559 1.48627 6.77982 1.14123 6.62765C0.798323 6.47549 0.528286 6.26332 0.331116 5.99114C0.13609 5.71896 0.0257178 5.40284 0 5.04279H0.822969C0.8444 5.2914 0.927983 5.49714 1.07372 5.66002C1.22159 5.82076 1.40805 5.94077 1.63308 6.02007C1.86025 6.09722 2.10457 6.1358 2.36603 6.1358C2.67036 6.1358 2.94361 6.08651 3.18579 5.98792C3.42796 5.88719 3.61978 5.74789 3.76122 5.57001C3.90267 5.38998 3.9734 5.17996 3.9734 4.93992C3.9734 4.72132 3.91232 4.54344 3.79016 4.40628C3.668 4.26912 3.50726 4.15767 3.30795 4.07195C3.10864 3.98622 2.89325 3.91121 2.66179 3.84692L1.85168 3.61546C1.33732 3.46758 0.930126 3.25648 0.630085 2.98216C0.330045 2.70784 0.180024 2.34886 0.180024 1.90523C0.180024 1.53661 0.279681 1.21513 0.478994 0.94081C0.680449 0.664344 0.950486 0.450029 1.2891 0.297866C1.62986 0.143559 2.01027 0.0664062 2.43033 0.0664062C2.85467 0.0664062 3.23187 0.142488 3.56191 0.294651C3.89196 0.444671 4.15342 0.650413 4.3463 0.911877C4.54133 1.17334 4.6442 1.47017 4.65492 1.80236H3.88338Z" fill="black"/>
                </svg>
            </Box>
        </Box>
    </DiscountNotificationTrigger>
);

export default memo(DiscountCodeBannerTrigger);
