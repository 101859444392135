import React, { memo, useEffect, useRef, useState } from "react";

// Import methods
import useDeviceDetect from "@/src/utils/customHooks/useDeviceDetect";

// Import Components
import Covid from "@/components/common/Covid/Covid";
import CompleteBookingCTA from "@/components/CTA/completeBooking";
import LoginCTA from "@/components/CTA/login";
import Words from "./components/Words";
import VerifySubscription from "./components/Verify";
import Image from "../../components/Image";
import { KsaImage } from '@/components/Image';
import { useHomeHeroImage, useResizeVHeight } from "./utils";
import { useRemoveContainerClass } from "@/src/utils/removeClass";
import SwitchToApp from "../SwitchToApp";
import { KsaRegion, ksaRegions } from "@/static/ksa";
import InTheMedia from "../InTheMedia";
// Enable for christmas
// import Snowfall from 'react-snowfall';

const Hero = ({ onChange }) => {
  // Check Device
  const { isMobile } = useDeviceDetect();

  // State
  const [imgSrc, setImgSrc] = useState("");

  // Call utils
  useResizeVHeight();
  useHomeHeroImage(setImgSrc);

  // This is a temporary fix, prior we fuck of useHomeHeroImage motherfucking crap
  const getViewportFallbackRef = useRef(() =>
    (typeof window !== 'undefined') && (window.innerWidth > 600)
      ? 'https://img.cuddlynest.com/images/assets/hero-day.jpg'
      : 'https://img.cuddlynest.com/images/assets/homepage/hero-day-mob-new.jpg');

  const [fallbackSrc, setFallbackSrc] = useState(getViewportFallbackRef.current);
  useRemoveContainerClass();

  useEffect(() => {

    const resizeHandler = () => {
    setFallbackSrc(getViewportFallbackRef.current());
    };
    window.addEventListener('resize', resizeHandler);

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);
  // end of temporary fix

  return (
    <div className="cn__hp__hero">
      <div className="cn__hp__hero__img">
        <KsaImage
          // Disable for Christmas
          regionsMap={ksaRegions}
          srcRegionMap={new Map([
            [KsaRegion.MiddleEast, `https://img.cuddlynest.com/assets/desktop/homepage/hero/Desktop-hero.webp`]
          ])}
          component={Image}
          src={imgSrc}
          alt="Cuddlynest Hero Image"
          loading="eager"
          fallbackSrc={fallbackSrc}
        />
        {/* Enable for christmas */}
        {/* <Snowfall
          snowflakeCount={100}
          speed={[.5, 1.5]}
          wind={[-1, .5]}
          color="#fff"
        /> */}
      </div>
      <Words />
      {/* {isMobile && (
        <div className="cn__hp__hero__covid">
          <Covid />
        </div>
      )} */}
      <LoginCTA />
      <SwitchToApp />
      <CompleteBookingCTA />
      {isMobile && (
        <div className="cn__hp__hero__info">
          <p>3m+ accommodations</p>
          <p>188+ countries</p>
          <p>32+ property types</p>
        </div>
      )}
      <VerifySubscription/>
      {!isMobile && <InTheMedia/>}
    </div>
  );
};

export default memo(Hero);
