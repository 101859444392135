import { memo } from "react";
import Box from "@mui/material/Box";
import { withExpiration } from "@/components/DiscountBanner";
import DiscountCodeBanner from "../../components/DiscountCodeBanner";
import { constants } from "@/utils/constants";

const DiscountCodeBannerComponent = withExpiration(settings => (
    <Box
        className='cn__hp__recentsearches'
        sx={{
            // Suck my dick business bitches
            '@media (max-width: 1200px)': {
                p: '0 !important',
                m: '30px auto !important',
            },
            mt:  '20px !important',
            '@media (max-width: 600px)': {
                mt: '20px !important',
            },
        }}
    >
        <DiscountCodeBanner
            settings={settings}
        />
    </Box>
));

const Discount = props => (
    <DiscountCodeBannerComponent
        {...props}
    />
);

Discount.defaultProps = {
    expiration: constants.discount.expiration
};

export default memo(Discount);
