import useDeviceDetect from '@/src/utils/customHooks/useDeviceDetect';
import React, { memo, useEffect, useState } from 'react';

const InTheMedia = () => {
    const { isMobile } = useDeviceDetect();
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsVisible(true);
        }, 1000); 
        return () => clearTimeout(timer); 
    }, []);

    if (!isVisible) {
        return null; 
    }
    return (
        <section className="cn__hp__media">
            <div className="cn__hp__media__wrapper">
                <div className="cn__hp__media__wrapper__item">Featured in:</div>
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isMobile ? 'mobile' : 'desktop'}/homepage/media/expresslogov2.png`} alt=""/>
                </div> 
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isMobile ? 'mobile' : 'desktop'}/homepage/media/travelnewslogov2.png`} alt=""/>
                </div>
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isMobile ? 'mobile' : 'desktop'}/homepage/media/strzlogov2.png`} alt=""/>
                </div>
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isMobile ? 'mobile' : 'desktop'}/homepage/media/phocuswirelogov2.png`} alt=""/>
                </div>
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isMobile ? 'mobile' : 'desktop'}/homepage/media/skiftlogov2.png`} alt=""/>
                </div>
                <div className="cn__hp__media__wrapper__item">
                    <img src={`https://img.cuddlynest.com/assets/${isMobile ? 'mobile' : 'desktop'}/homepage/media/Travelpulselogov2.png`} alt=""/>
                </div>
            </div>
        </section>
    )
}

export default memo(InTheMedia);